<template>
  <div
    class="color-picker-wrapper"
    v-closable="{ handler: 'onClose', allowed: 'resetColor' }"
  >
    <button
      :class="['btn', buttonClass]"
      v-on:click.prevent="onClick"
      :disabled="disabled"
      data-testid="show"
    >
      <div
        class="bgcolor"
        ref="bgcolor"
        v-bind:style="{ 'background-color': color, 'border-color': color }"
      >
        <svg
          v-if="icon == 'foreground'"
          data-testid="foreground-icon"
          height="22px"
          width="22px"
          v-bind:fill="iconColor"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="5 0 100 100"
          enable-background="new 0 0 100 100"
          xml:space="preserve"
        >
          <g>
            <path
              d="M70.2125168,22.2607307H29.7874832c-0.6352367,0-1.1550026,0.5197639-1.1550026,1.1550007v18.5493145   c0,0.76791,0.0790272,1.5174904,0.2238941,2.2436638h42.287674c0.1445847-0.7261734,0.223465-1.4757538,0.223465-2.2436638   V23.4157314C71.3675156,22.7804947,70.859314,22.2607307,70.2125168,22.2607307z"
            ></path>
            <path
              d="M40.1247559,53.4457588h4.6818275v18.5185051c0,3.1841507,2.5908585,5.7750092,5.7761345,5.7750092   c3.1841469,0,5.7750053-2.5908585,5.7750053-5.7750092V53.4457588h3.5290833c4.7117882,0,8.7667732-2.8567657,10.533741-6.9270477   H29.5813522C31.3515701,50.5889931,35.4130363,53.4457588,40.1247559,53.4457588z M51.7377167,70.773735   c0,0.6378403-0.5165901,1.1549988-1.1549988,1.1549988s-1.1550026-0.5171585-1.1550026-1.1549988v-2.3100052   c0-0.6378403,0.5165939-1.1549988,1.1550026-1.1549988s1.1549988,0.5171585,1.1549988,1.1549988V70.773735z"
            ></path>
          </g>
        </svg>
        <svg
          v-if="icon == 'background'"
          data-testid="background-icon"
          height="18px"
          width="18px"
          v-bind:fill="iconColor"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.0"
          x="0px"
          y="0px"
          viewBox="0 -2 24 24"
          enable-background="new 0 0 24 24"
          xml:space="preserve"
        >
          <path
            fill="none"
            v-bind:stroke="iconColor"
            stroke-width="2"
            stroke-miterlimit="10"
            d="M9.6,20.4l-5.1-5.1c-0.8-0.8-0.8-2,0-2.8L11,6.2  l6.4,6.4c0.8,0.8,0.8,2,0,2.8l-5.1,5.1C11.6,21.2,10.4,21.2,9.6,20.4z"
          ></path>
          <path
            d="M22,20c0,1.1-0.9,2-2,2s-2-0.9-2-2s2-4,2-4S22,18.9,22,20z"
          ></path>
          <path
            d="M4,14c0,0.5,0.2,1,0.5,1.4l5.1,5.1c0.8,0.8,2,0.8,2.8,0l5.1-5.1C17.8,15,18,14.5,18,14H4z"
          ></path>
          <line
            fill="none"
            v-bind:stroke="iconColor"
            stroke-width="2"
            stroke-miterlimit="10"
            x1="7.5"
            y1="2.7"
            x2="15.4"
            y2="10.6"
          ></line>
        </svg>
        <i
          v-if="icon && icon != 'foreground' && icon != 'background'"
          data-testid="icon"
          v-bind:style="{ color: iconColor }"
          v-bind:class="icon"
        ></i>
      </div>
    </button>
    <transition name="slide-fade">
      <color-picker
        class="picker"
        v-if="show"
        theme="light"
        :color="color"
        :sucker-hide="!sucker"
        :sucker-canvas="suckerCanvas"
        :sucker-area="suckerArea"
        @changeColor="changeColor"
        @openSucker="openSucker"
        @close="close"
        @undo="resetColor"
        v-bind:style="defaultPickerPosition"
        data-testid="picker"
        :key="colorReset"
      />
    </transition>
  </div>
</template>
<script>
import colorPicker from "@/utils/vue-colorpicker";
import ClosableDirective from "@/directives/closable";
import { debounceTime, clampDownPosition } from "@/utils";

export default {
  name: "ColorPicker",
  directives: { closable: ClosableDirective },
  components: {
    colorPicker
  },
  props: {
    value: {
      type: String,
      default: () => "#59c7f9",
      required: false
    },
    icon: {
      type: String,
      default: () => "", // "foreground || background || glyphicon-text-background || glyphicon glyphicon-text-color",
      required: false
    },
    sucker: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonClass: {
      type: String,
      required: false,
      default: "btn-sm"
    },
    updateOnCloseOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      color: "#FFFFFF",
      emitColor: "",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      show: false,
      colorReset: Math.random(),
      floatPanel: false,
      standardColor: ""
    };
  },
  computed: {
    iconColor() {
      let r,
        b,
        g,
        hsp,
        a = this.color;
      if (a.match(/^rgb/)) {
        a = a.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );
        r = a[1];
        g = a[2];
        b = a[3];
      } else {
        a = +(
          "0x" +
          a.slice(1).replace(
            // thanks to jed : http://gist.github.com/983661
            a.length < 5 && /./g,
            "$&$&"
          )
        );
        r = a >> 16;
        b = (a >> 8) & 255;
        g = a & 255;
      }
      hsp = Math.sqrt(
        // HSP equation from http://alienryderflex.com/hsp.html
        0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
      );
      if (hsp > 127.5) {
        return "#545454";
      } else {
        return "#b7b3b3";
      }
    },
    defaultPickerPosition() {
      const pickerWidth = 220,
        pickerHeight = 375;
      let wrapperRect = this.$el.getBoundingClientRect();
      if (this.floatPanel) {
        return {
          left: `${wrapperRect.width}px`,
          top: `0px`,
          position: "absolute"
        };
      }
      let rect = {};
      rect.top = wrapperRect.y + wrapperRect.height - 5;
      rect.left = wrapperRect.x;
      rect.bottom = rect.top + pickerHeight;
      rect.right = rect.left + pickerWidth;
      // clamps down the position to window boundaries,
      // leaving a space of 25px from the edge
      return clampDownPosition(rect, null, 25);
    }
  },
  methods: {
    resetButtonPosition() {
      return {
        position: this.floatPanel ? "absolute" : "fixed",
        "min-width": "100px",
        top:
          parseInt(this.defaultPickerPosition.top.replace(/px/, "")) -
          20 +
          "px",
        left:
          parseInt(this.defaultPickerPosition.left.replace(/px/, "")) +
          110 +
          "px"
      };
    },
    close() {
      this.show = false;
    },
    onClose(command) {
      if (this.show && this.updateOnCloseOnly) {
        this.$emit("input", this.emitColor);
      }
      this.show = false;
    },
    onClick() {
      if (this.show && this.updateOnCloseOnly) {
        this.$emit("input", this.emitColor);
      }
      this.show = !this.show;
    },
    delayedDispatch: debounceTime(1000, function () {
      this.$emit("input", this.emitColor);
      if (this.emitColor == this.standardColor) {
        this.close();
      }
      this.standardColor = "";
    }),
    changeColor(color) {
      const { r, g, b, a } = color.rgba;
      this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
      this.emitColor = this.color;
      var fDefault =
        this?.$options?.components?.colorPicker?.props?.colorsDefault
          ?.default || null;
      var fColors = (fDefault && fDefault()) || [];
      if (color && color?.hex && fColors.indexOf(color.hex) >= 0) {
        this.standardColor = this.emitColor;
        this.$emit("input", this.emitColor);
        this.delayedDispatch();
      }
      if (!this.updateOnCloseOnly) this.delayedDispatch();
    },
    openSucker(isOpen) {
      // TODO - create a canvas with screen dimension
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    },
    resetColor() {
      this.color = "rgba(255, 255, 255, 0)";
      this.colorReset = Math.random();
      this.emitColor = "inherit";
      if (!this.updateOnCloseOnly) this.$emit("input", this.emitColor);
    }
  },
  watch: {
    value: {
      handler(n) {
        this.color =
          n == "transparent" || n == "inherit" ? "rgba(255,255,255,0)" : n;
        this.emitColor = n;
      },
      immediate: true
    }
  },
  mounted() {
    if (this.$el.closest(".float-panel")) {
      this.floatPanel = true;
    }
  }
};
</script>

<style scoped>
#reset-color {
  /* position: absolute; */
  z-index: 10000;
  /* overwrite unwanted .btn-group-justifed styles */
  border-radius: 3px;
  width: auto;
}
</style>
<style>
.color-picker-wrapper {
  position: relative;
  white-space: normal;
}

.color-picker-wrapper > .btn-sm {
  padding: 2px;
}

.color-picker-wrapper > .btn-sm > .bgcolor {
  border: 1px solid gray;
  border-radius: 3px;
  width: 22px;
  height: 22px;
}

.color-picker-wrapper > .btn-sm > .bgcolor > .i {
  vertical-align: middle;
}

.picker {
  width: 220px !important;
  /* position: absolute; */
  position: fixed;
  top: 32px;
  right: -97px;
  z-index: 9999;
}

.picker > .color-set > .hue > .slide,
.picker > .color-set > .color-alpha > .slide {
  width: 110%;
  background: #6b6a60;
  margin-left: -4%;
  border-radius: 5px;
}
.header-buttons {
  background-color: white;
  z-index: 5;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-in;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px) translateX(-20px);
  opacity: 0;
}
</style>
